import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page l4nr7urljd-editor_css' },
  logo: {
    className: 'header0-logo l4nr6alyt57-editor_css',
    children: 'https://i1.izhengche.com/prod/logo.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#home',
          children: [{ children: '首页', name: 'text' }],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#service',
          children: [
            {
              children: (
                <span>
                  <p>产品服务</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      // {
      //   name: 'item3',
      //   className: 'header0-item',
      //   children: {
      //     href: '#aboutus',
      //     children: [
      //       {
      //         children: (
      //           <span>
      //             <p>关于我们</p>
      //           </span>
      //         ),
      //         name: 'text',
      //       },
      //     ],
      //   },
      // },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title l4nom84cgvi-editor_css',
    children: '4S店二手车业务',
  },
  content: {
    className: 'banner0-content',
    icons: '+',
    left: '数字化',
    right: '规模化',
  },
  button: { className: 'banner0-button', children: '400 050 1058' },
  description: {
    className: 'banner0-description',
    children: '升级专业服务商',
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0 l4mnf6zlzg-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '我的服务' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 24,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: 
            {
              image: "https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png",
              title: "数字化系统",
              h1: "为4S店量身定制",
              h2: "通过数字化工具将非标商品标准化、非标管理标准化",
              h3: "通过市场化链接代替员工与车商的链接，使定况、定价、处置更高效、更精准、更健康"
            }
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 24,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: 
          {
            image: "https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png",
            title: "专业管理陪伴",
            h1: "20年4S店品牌二手车管理专家团队",
            h2: "监管式陪伴：帮助4S店有效管理灰色区、舒适区",
            h3: "教练式陪伴：帮助员工在4S店的非市场化环境中构建市场化二手车集车/集客能力"
          }
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 24,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children:{
            image: "https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png",
            title: "资源引流",
            content: "",
            h1: "产业批量车资源引流，助力4S店规模化集车",
            h2: "产业生态客源引流，助力4S店规模化集客",
            h3: ""
          }
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: {
    className: 'home-page-wrapper content3-wrapper l4nk8ipkbvs-editor_css',
  },
  page: { className: 'home-page content3 l4mn6tghfpf-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          '正车提供专业的服务'
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          '基于正车互联强大的SaaS平台'
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
                '移动互联网'
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              '移动端APP、H5网站、微信小程序云店、商城、抖音小程序、快手小程序'
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云安全' },
          content: {
            className: 'content3-content',
            children:
              '按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: ('聚流提效'),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>对内提高研发和业务运营效率，对外聚合流量推动业务创新。</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              '稳定可靠'
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              '虚拟化云服务无惧大吞吐量请求和数据交互，多地灾备策略及快速恢复机制保障系统的持续稳定运行。'
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
             '人工智能'
            ),
          },
          content: {
            className: 'content3-content',
            children: '图像识别、文本分析、自然语言处理，人脸人体等视觉AI技术应用落地。',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '大数据' },
          content: {
            className: 'content3-content',
            children:'一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
          },
        },
      },
    ],
  },
};
export const Teams40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content8-wrapper l4nj79qzr7a-editor_css',
  },
  page: { className: 'home-page content8 l4nj684a7eq-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: '我的产品',
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content-wrapper',
    children: [
      {
        name: 'block0',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://i1.izhengche.com/prod/jiance.png',
          },
          title: {
            className: 'content8-title',
            children: '车观查',
          },
          content: { className: 'content8-content', children: '' },
        },
      },
      {
        name: 'block2',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://i1.izhengche.com/prod/zcyd.jpg',
          },
          title: {
            className: 'content8-title',
            children: '正车云店',
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://i1.izhengche.com/prod/zct.jpg',
          },
          title: {
            className: 'content8-title',
            children: '正车通',
          },
          content: {
            className: 'content8-content',
            children: (
              <span>
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://i1.izhengche.com/prod/zyescsc.jpg',
          },
          title: {
            className: 'content8-title',
            children: '正舆二手车商城',
          },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block4',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://i1.izhengche.com/prod/zcb.jpg',
          },
          title: {
            className: 'content8-title',
            children: '正车宝',
          },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block5',
        md: 8,
        xs: 24,
        className: 'content8-block-wrapper',
        children: {
          className: 'content8-block',
          img: {
            className: 'content8-img',
            children: 'https://i1.izhengche.com/prod/wyq.jpg',
          },
          title: {
            className: 'content8-title',
            children: '无忧骑',
          },
          content: {
            className: 'content8-content',
            children:'',
          },
        },
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper l4npam9pbga-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '关于我们',
        className: 'title-h1',
      },
      {
        name: 'content',
        children: '正舆二手车',
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
                    <div >
                      <h3>概述：</h3>
                      <p>
                        正舆隶属于戊戌车正科技（北京）有限责任公司旗下品牌，现授权于正车互联（北京）信息科技有限公司，主要服务内容包含为商品和服务的买卖双方提供在线市场，商业中介服务，市场营销、组织商业或广告展览，
                        货物展出以及进出口代理等。
                      </p>
                      <h3>基本信息：</h3>
                      <p>中文名：正舆</p>
                      <p>所属公司：戊戌车正科技（北京）有限责任公司</p>
                      <p>注册号：45909323</p>
                      <h3>品牌简介：</h3>
                      <p>
                        正舆二手车成立于2020年，创始人任玉凯。正舆二手车致力于为二手车行业提供数字化升级的解决方案，打造国内二手车买方市场的联盟品牌。
                      </p>
                      <h3>主营业务：</h3>
                      <p>
                        正舆二手车的主营业务包括二手车品牌加盟、二手车SAAS管理系统、二手车出险和维保查询、二手车专业定价、二手车延保、二手车咨询和服务、新能源二手车置换业务赋能、批量车分销等。
                      </p>
                      <h3>企业特色：</h3>
                      <p>
                        正舆二手车的数据化工具实用性高，具体功能涉及到二手车进、销、存的每一个场景，包括二手车的数据化检测、客户线索维护、透明化看板、任务分发与管理、过程指标跟踪、数据分析、营销工具等。
                      </p>
                      <p>
                        正舆二手车最大的特色是基于一整套标准化、工具化的管理系统，在此基础上为加盟商户提供专业化的贴身运营，用来解决二手车这类非标准化商品运营的管理难题，打通上下游行业资源，构建品牌加盟商从收车和卖车的全流程运营能力。
                      </p>
                      <h3>核心团队：</h3>
                      <p>
                        正舆二手车的核心团队是原优信二手车和瓜子二手车零售业务的主要负责人，并且在互联网和传统二手车行业平均工作经验累计超过15年。
                      </p>
                      <p>
                        正舆二手车的核心高管具有传统公司和互联网公司的双重经验，对业务需求的判断能够应用逻辑性思考和定性分析，具有将经验转化成标准化运营能力、进而转化成管理系统的能力。
                      </p>
                      <h3>品牌理念：</h3>
                      <p>
                        由于二手车经营的复杂度比较高，因此很难形成全国性的二手车零售品牌。正舆二手车致力于成为业内领先的综合后市场服务商，在品牌建立、运营能力、综合赋能方面解决用户购买二手车的痛点和二手车经营者的经营难题，以“正”为始，策马飞“舆”。
                      </p>
                      
                      </div>
        ),
        className: 'title-content l4np9aoa6s-editor_css',
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <div> 
         <p> ©2020-2022 正车互联 All Rights Reserved </p>
         <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">京ICP备2021006235号-2</a>
       </div>
      )
  },
};
