/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';

import Nav0 from './Nav0';
import Banner0 from './Banner0';
import Content0 from './Content0';
// import Content3 from './Content3';
import Teams4 from './Teams4';
// import Content13 from './Content13';
import Footer0 from './Footer0';

import MNav0 from '../Mobile/Nav0';

import MBanner0 from '../Mobile/Banner0';
import MContent0 from '../Mobile/Content0';
// import Content1 from './Content1';
import MContent3 from '../Mobile/Content3';

import {
  Nav00DataSource,
  Banner00DataSource,
  Content00DataSource,
  // Content30DataSource,
  Teams40DataSource,
  // Content130DataSource,
  Footer00DataSource,
} from './data.source';
import {
  MNav00DataSource,
  MBanner00DataSource,
  MContent00DataSource,
  // Content10DataSource,
  MContent30DataSource,
  MFooter00DataSource,
} from '../Mobile/data.source';
import './less/antMotionStyle.less';
import '../Mobile/less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const { location = {} } = typeof window !== 'undefined' ? window : {};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {

    const pc = [
      <Nav0
        id="nav_0"
        key="nav_0"
        dataSource={Nav00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner0
        id="home"
        key="home"
        dataSource={Banner00DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content0
        id="service"
        key="service"
        dataSource={Content00DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Content3
      //   id="Content3_0"
      //   key="Content3_0"
      //   dataSource={Content30DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Teams4
        id="Teams4_0"
        key="Teams4_0"
        dataSource={Teams40DataSource}
        isMobile={this.state.isMobile}
      />,
      // <Content13
      //   id="aboutus"
      //   key="aboutus"
      //   dataSource={Content130DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Footer0
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />,
    ];

    const mc = [
      <MNav0
      id="Nav0_0"
      key="Nav0_0"
      dataSource={MNav00DataSource}
      isMobile={this.state.isMobile}
    />,
    <MBanner0
      id="mobile"
      key="mobile"
      dataSource={MBanner00DataSource}
      isMobile={this.state.isMobile}
    />,
    <MContent0
      id="service"
      key="service"
      dataSource={MContent00DataSource}
      isMobile={this.state.isMobile}
    />,
    // <Content1
    //   id="Content1_0"
    //   key="Content1_0"
    //   dataSource={Content10DataSource}
    //   isMobile={this.state.isMobile}
    // />,
    <MContent3
      id="app_service"
      key="app_service"
      dataSource={MContent30DataSource}
      isMobile={this.state.isMobile}
    />,
    <Footer0
      id="Footer0_0"
      key="Footer0_0"
      dataSource={MFooter00DataSource}
      isMobile={this.state.isMobile}
    />
    ]

    const children = this.state.isMobile ? mc : pc ;
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
