import React from 'react';
export const MNav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'https://i1.izhengche.com/prod/logo.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '#home',
          children: [{ children: '首页', name: 'text' }],
        },
        subItem: null,
      },
      {
        name: 'item1',
        className: 'header0-item',
        children: {
          href: '#service',
          children: [
            {
              children: (
                <span>
                  <p>产品服务</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      }
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const MBanner00DataSource = {
  wrapper: { className: 'Mbanner0' },
  textWrapper: { className: 'Mbanner0-text-wrapper' },
  title: {
    className: 'Mbanner0-title m5ukxwvyzom-editor_css',
    children: "4S店二手车业务",
  },
  content: {
    className: 'Mbanner0-content',
    children: <div >数字化 + 规模化 </div>,
  },

  button: { className: 'Mbanner0-button', children: '400 050 1058' },
  description: {
    className: 'Mbanner0-description',
    children: '升级专业服务商',
  },
};

export const MContent10DataSource = {
  wrapper: { className: 'm-home-page-wrapper content1-wrapper' },
  OverPack: { className: 'm-home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: { className: 'content1-title', children: '企业资源管理' },
  content: {
    className: 'content1-content',
    children:
      '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
  },
};

export const MContent00DataSource = {
  wrapper: { className: 'm-home-page-wrapper m-content0-wrapper' },
  page: { className: 'm-home-page m-content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '我的服务' }],
  },
  childWrapper: {
    className: 'm-content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'm-content0-block',
        md: 24,
        xs: 24,
        children:  <div className="wrap-box" key="1">
        <div  className='left-box' key="1">
          <div name="image" className="m-content0-block-icon"><img src="https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png" alt="img" /></div>
          <h1 name="title" className="m-content0-block-title">数字化系统</h1>
        </div>
        <div  className='right-box'>
          <div className='m-content0-block-content'> 
              <div> 为4S店量身定制 </div>
              <div> 通过数字化工具将非标商品标准化、非标管理标准化 </div>
              <div> 通过市场化链接代替员工与车商的链接，使定况、定价、处置更高效、更精准、更健康 </div>
          </div>
        </div>
      </div>
     },
      {
        name: 'block1',
        className: 'm-content0-block',
        md: 24,
        xs: 24,
        children: <div className="wrap-box" key="2">
        <div  className='left-box' key="2">
          <div name="image" className="m-content0-block-icon"><img src="https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png" alt="img" /></div>
          <h1 name="title" className="m-content0-block-title">专业管理陪伴</h1>
        </div>
        <div  className='right-box'>
          <div className='m-content0-block-content'> 
              <div> 20年4S店品牌二手车管理专家团队 </div>
              <div> 监管式陪伴：帮助4S店有效管理灰色区、舒适区 </div>
              <div> 教练式陪伴：帮助员工在4S店的非市场化环境中构建市场化二手车集车/集客能力 </div>
          </div>
        </div>
      </div>
      },
      {
        name: 'block2',
        className: 'm-content0-block',
        md: 24,
        xs: 24,
        children: <div className="wrap-box" key="3">
        <div  className='left-box' key="3">
          <div name="image" className="m-content0-block-icon"><img src="https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png" alt="img" /></div>
          <h1 name="title" className="m-content0-block-title">资源引流</h1>
        </div>
        <div  className='right-box'>
          <div className='m-content0-block-content'> 
              <div> 产业批量车资源引流，助力4S店规模化集车 </div>
              <div> 监管式陪伴：帮助4S店有效管理灰色区、舒适区 </div>
              <div> 产业生态客源引流，助力4S店规模化集客 </div>
          </div>
        </div>
      </div>
      },
    ],
  },
};

export const MContent30DataSource = {
  wrapper: { className: 'm-home-page-wrapper m-content3-wrapper' },
  page: { className: 'm-home-page m-content3' },
  OverPack: { playScale: 0.3 },
  // titleWrapper: {
  //   className: 'title-wrapper',
  //   children: [
  //     {
  //       name: 'title',
  //       children: '蚂蚁金融云提供专业的服务',
  //       className: 'title-h1',
  //     },
  //     {
  //       name: 'content',
  //       className: 'title-content',
  //       children: '基于阿里云强大的基础资源',
  //     },
  //   ],
  // },
  block: {
    className: 'm-content3-block-wrapper',
    children: [
      {
        name: 'block0',
        md: 8,
        xs: 24,
        className: 'm-content8-block-wrapper',
        children: {
          className: 'm-content8-block',
          img: {
            className: 'm-content8-img',
            children: 'https://i1.izhengche.com/prod/jiance.png',
          },
          title: {
            className: 'm-content8-title',
            children: '车观查',
          },
          content: { className: 'content8-content', children: '' },
        },
      },
      {
        name: 'block2',
        md: 8,
        xs: 24,
        className: 'm-content8-block-wrapper',
        children: {
          className: 'm-content8-block',
          img: {
            className: 'content8-img',
            children: 'https://i1.izhengche.com/prod/zcyd.jpg',
          },
          title: {
            className: 'm-content8-title',
            children: '正车云店',
          },
          content: {
            className: 'm-content8-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        md: 8,
        xs: 24,
        className: 'm-content8-block-wrapper',
        children: {
          className: 'm-content8-block',
          img: {
            className: 'm-content8-img',
            children: 'https://i1.izhengche.com/prod/zct.jpg',
          },
          title: {
            className: 'm-content8-title',
            children: '正车通',
          },
          content: {
            className: 'm-content8-content',
            children: (
              <span>
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        md: 8,
        xs: 24,
        className: 'm-content8-block-wrapper',
        children: {
          className: 'm-content8-block',
          img: {
            className: 'm-content8-img',
            children: 'https://i1.izhengche.com/prod/zyescsc.jpg',
          },
          title: {
            className: 'm-content8-title',
            children: '正舆二手车商城',
          },
          content: {
            className: 'content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block4',
        md: 8,
        xs: 24,
        className: 'm-content8-block-wrapper',
        children: {
          className: 'm-content8-block',
          img: {
            className: 'm-content8-img',
            children: 'https://i1.izhengche.com/prod/zcb.jpg',
          },
          title: {
            className: 'm-content8-title',
            children: '正车宝',
          },
          content: {
            className: 'm-content8-content',
            children: '',
          },
        },
      },
      {
        name: 'block5',
        md: 8,
        xs: 24,
        className: 'm-content8-block-wrapper',
        children: {
          className: 'm-content8-block',
          img: {
            className: 'm-content8-img',
            children: 'https://i1.izhengche.com/prod/wyq.jpg',
          },
          title: {
            className: 'm-content8-title',
            children: '无忧骑',
          },
          content: {
            className: 'm-content8-content',
            children:'',
          },
        },
      },
    ],
  },
};
export const MFooter00DataSource = {
  wrapper: { className: 'm-home-page-wrapper m-footer0-wrapper' },
  OverPack: { className: 'm-home-page m-footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <div>
          <p> ©2020-2022 正车互联 All Rights Reserved </p>
         <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">京ICP备2021006235号-2</a>
      </div>
    ),
  },
};
